import { arrayToObject, EmptyArray, nonNullable } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { getInsuranceItems } from './insurance'
import getCheckoutSelectedQuotes from './getCheckoutSelectedQuote'
import { getOrderInsuranceUpdateQuoteKey } from 'lib/order/orderInsuranceUtils'
import getTotalsWithoutInsurance from '../payment/getTotalsWithoutInsurance'
import { getLuxLoyaltyInsuranceProductType } from 'luxLoyalty/lib/getLuxLoyaltyInsuranceProductType'
import { selectShouldUseInsuranceMemberPrice } from './luxPlusSubscription'
import getCanRedeemInsuranceLoyaltyBenefit from 'luxLoyalty/selectors/checkout/loyalty/getCanRedeemInsuranceLoyaltyBenefit'

/**
 * Retrieves a record of item id -> update quote for all post-purchase
 * insurance items currently in the checkout
 */
const getCheckoutSelectedQuoteUpdates = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder?.insuranceItems,
  (state: App.State) => getInsuranceItems(state),
  (state: App.State) => getTotalsWithoutInsurance(state).data.price,
  (state: App.State) => state.insurance.updateQuotes,
  (orderItems, items, price, updateQuotes): Record<string, App.InsuranceUpdateQuote | undefined> => {
    return arrayToObject(items,
      item => item.itemId,
      item => {
        const orderItem = orderItems?.find(orderItem => orderItem.id === item.orderItemId)
        if (orderItem) {
          const key = getOrderInsuranceUpdateQuoteKey({
            itemId: orderItem.id,
            startDate: item.startDate ?? orderItem.startDate,
            endDate: item.endDate ?? orderItem.endDate,
            travellers: orderItem.travellers,
            coverageAmount: item.coverageAmount ?? orderItem.coverAmount + price,
          })

          return updateQuotes[key]?.quote
        }
      })
  })

const getInsuranceItemsView = createSelector(
  (state: App.State) => getInsuranceItems(state),
  (state: App.State) => state.insurance.products,
  (state: App.State) => getCheckoutSelectedQuotes(state),
  (state: App.State) => getCheckoutSelectedQuoteUpdates(state),
  (state: App.State) => state.checkout.cart.existingOrder?.insuranceItems,
  (state: App.State) => selectShouldUseInsuranceMemberPrice(state),
  (state: App.State) => getCanRedeemInsuranceLoyaltyBenefit(state),
  (
    insuranceItems,
    products,
    quotes,
    updateQuotes,
    orderInsuranceItems = EmptyArray,
    selectShouldUseInsuranceMemberPrice,
    canRedeemInsuranceLoyaltyBenefit,
  ): App.WithDataStatus<Array<App.Checkout.InsuranceItemView>> => {
    let hasRequiredData = true
    const itemViews = insuranceItems.map((item): App.Checkout.InsuranceItemView | null => {
      if (item.productId) {
        const product = products[item.productId]
        const quote = quotes[item.itemId]
        hasRequiredData = !!(product && quote)
        if (product && quote) {
          const selectedPolicyIds = new Set(item.policyIds)
          const selectedPolicyNames = product.policyOptions
            .filter(option => selectedPolicyIds.has(option.policy))
            .map(option => option.name)
          const policyNames = product.policyNames.length ? product.policyNames : selectedPolicyNames
          let benefits: Array<string> = []
          const isCustomizedProtectionSelected = selectedPolicyNames.length > 0
          if (isCustomizedProtectionSelected) {
            benefits = item.policyIds?.flatMap((selectedPolicyId) => {
              return product.policyOptions?.find((p) => p.policy === selectedPolicyId)?.keyBenefits?.map(benefit => benefit.label) ?? []
            }) ?? []
          } else {
            benefits = product.keyBenefits.map((benefit) => benefit.label)
          }

          const total = canRedeemInsuranceLoyaltyBenefit ? quote.loyaltyTotal : quote.total
          const useMobilePrice = quote.mobileAppOnlyPrice && !canRedeemInsuranceLoyaltyBenefit

          return {
            item,
            luxLoyaltyProductType: getLuxLoyaltyInsuranceProductType(item.insuranceType),
            quoteId: quote.id,
            productId: item.productId,
            name: product.name,
            policyNames,
            startDate: quote.startDate,
            endDate: quote.endDate,
            destinationCountries: quote.destinationCountries,
            totals: {
              price: useMobilePrice ? quote.mobileAppOnlyPrice : total,
              memberPrice: selectShouldUseInsuranceMemberPrice ? quote.luxPlusTotal : 0,
              value: quote.total,
              surcharge: 0,
              taxesAndFees: 0,
              ...(useMobilePrice && quote.mobileAppOnlyPrice > 0 && {
                mobileAppDiscount: {
                  amount: quote.total - quote.mobileAppOnlyPrice,
                  percentage: 20,
                },
              }),
              memberValue: 0,
              extraGuestSurcharge: 0,
            },
            benefits,
            mobileAppPrice: useMobilePrice ? quote.mobileAppOnlyPrice ?? 0 : 0,
            coverageAmount: quote.coverageAmount,
          }
        }
      } else if (item.orderItemId) {
        const updateQuote = updateQuotes[item.itemId]
        hasRequiredData = !!updateQuote
        if (updateQuote) {
          const orderItem = orderInsuranceItems.find(orderItem => orderItem.id === item.orderItemId)!

          return {
            item,
            luxLoyaltyProductType: getLuxLoyaltyInsuranceProductType(item.insuranceType),
            updateQuoteId: updateQuote.id,
            productId: orderItem.idProduct!,
            name: orderItem.productName,
            policyNames: orderItem.policyNames,
            startDate: updateQuote.startDate,
            endDate: updateQuote.endDate,
            destinationCountries: orderItem.destinations,
            totals: {
              price: updateQuote.totalPriceDiff,
              // note: member price not implemented on update quotes yet?
              memberPrice: updateQuote.totalPriceDiff,
              value: updateQuote.total,
              surcharge: 0,
              taxesAndFees: 0,
              memberValue: 0,
              extraGuestSurcharge: 0,
            },
            coverageAmount: updateQuote.coverageAmount,
            benefits: [],
            mobileAppPrice: 0,
          }
        }
      }

      return null
    })

    return {
      hasRequiredData,
      data: nonNullable(itemViews),
    }
  },
)

export default getInsuranceItemsView
