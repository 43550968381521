import { createSelector } from 'reselect'
import { isBedbankAccommodationItemView, isBedbankItem, isLEHotelAccommodationItemView, isLEHotelItem, isVillaItem } from 'lib/checkout/checkoutUtils'
import { isNonEmptyArray, min, sum, take, unique } from 'lib/array/arrayUtils'
import { getExperienceListKey, mapExperienceToBookingView } from 'lib/experiences/experienceUtils'
import config from 'constants/config'
import { getExperienceItems, getTransferItems } from './view/experience'
import { EmptyExperienceList, EXPERIENCE_IN_FLOW_MAX_SHOWN, EXP_CATEGORY_AIRPORT_LOUNGE_ID, EXP_CATEGORY_AIRPORT_TRANSFER_ID } from 'constants/experience'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { checkoutAccommodationOfferView, getAccommodationEndDate, getAccommodationItems, getAccommodationStartDate } from './view/accommodation'
import { getFullOffers } from 'selectors/offerSelectors'
import { getVillaItems } from 'checkout/selectors/view/villa'
import { getMerchantFeeByOrderItem } from 'lib/payment/paymentUtils'
import { isExperienceItemUpcoming, isTransferItemUpcoming } from 'lib/order/isUpcoming'
import { EXPERIENCE_TRANSFER_ID } from '../../client/constants/experience'
import { isEmptyObject } from 'lib/object/objectUtils'
import { getDefaultTransfers } from 'checkout/Components/Experiences/ExperienceCheckoutTransferBookingModal'
import { findBestTransfer } from 'components/Experiences/ExperienceTransfers/ExperienceTransferUtils'
import { hasFijiHotelInCart } from './hasFijiHotelInCart'

export const getLeOfferInCart = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => state.offer.offers,
  (items, leOffers): App.Offer | undefined => {
    // only the first offer will be considered now
    const item = items[0]
    if (item && isLEHotelItem(item)) {
      return leOffers?.[item.offerId]
    }
  },
)

export const getVillaOfferInCart = createSelector(
  (state: App.State) => getVillaItems(state),
  (state: App.State) => state.offer.offers,
  (items, villaOffers): App.Offer | undefined => {
    // only the first offer will be considered now
    const item = items[0]
    if (item && isVillaItem(item)) {
      return villaOffers?.[item.offerId]
    }
  },
)

export const getBedbankOfferInCart = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => getFullOffers(state),
  (items, offers): App.BedbankOffer | undefined => {
    // only the first offer will be considered now
    const item = items[0]
    if (item && isBedbankItem(item)) {
      return offers?.[item.offerId] as App.BedbankOffer
    }
  },
)

export const getExperiencesEnabled = createSelector(
  (state: App.State) => getLeOfferInCart(state),
  (state: App.State) => getVillaOfferInCart(state),
  (state: App.State) => getBedbankOfferInCart(state),
  (offer, villaOffer, bedBankOffer) => {
    if (offer) {
      return config.EXPERIENCES_ENABLED && offer.experiencesInFlow
    }

    if (villaOffer) {
      return config.EXPERIENCES_ENABLED && villaOffer.experiencesInFlow
    }

    if (bedBankOffer) {
      return config.EXPERIENCES_ENABLED && config.EXPERIENCES_BEDBANK_INFLOW_ENABLED
    }

    return config.EXPERIENCES_ENABLED
  },
)

export const getCartOfferGeoCoords = createSelector(
  (state: App.State) => getLeOfferInCart(state),
  (state: App.State) => getVillaOfferInCart(state),
  (state: App.State) => getBedbankOfferInCart(state),
  (offer?: App.Offer, villaOffer?, bedbankOffer?: App.BedbankOffer) => {
    const currentOffer = offer || villaOffer || bedbankOffer

    if (currentOffer) {
      return {
        latitude: currentOffer.property?.latitude,
        longitude: currentOffer.property?.longitude,
      }
    } else {
      return {}
    }
  },
)

export const getCartOfferLocation = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.bedbankOffers,
  (items, leOffers, bedbankOffers) => {
    // only the first offer will be considered now
    const item = items[0]
    if (item && isLEHotelItem(item)) {
      const offer = leOffers?.[item.offerId]
      return offer?.location ?? ''
    }
    if (item && isBedbankItem(item)) {
      const offer = bedbankOffers?.[item.offerId]
      const property = offer?.property
      if (property) {
        return [property.address.city, property.address.stateProvinceName].filter(Boolean).join(', ')
      }
    }
    return ''
  },
)

export const getCheckoutExperienceDateRange = createSelector(
  (state: App.State) => getAccommodationStartDate(state),
  (state: App.State) => getAccommodationEndDate(state),
  (from, to) => {
    return {
      from: from ? moment(from).add(-7, 'days').format(ISO_DATE_FORMAT) : undefined,
      to: to ? moment(to).add(7, 'days').format(ISO_DATE_FORMAT) : undefined,
    }
  },
)

export const getExperienceOfferList = createSelector(
  (state: App.State) => getCartOfferGeoCoords(state),
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.experience.experienceLists,
  (state: App.State) => getLeOfferInCart(state),
  (state: App.State) => getCheckoutExperienceDateRange(state),
  (state: App.State) => getExperiencesEnabled(state),
  (geoCoords, currencyCode, lists, offer, availabilityParams, isExperienceEnabled): App.ApiCallState<{ids: Array<string>}> => {
    const isCurated = !!offer?.experiencesCurated
    const showOnlyCurated = !!offer?.showOnlyExperiencesCurated

    const hotelExperiencesListKey = getExperienceListKey({
      currencyCode,
      offerId: offer?.id,
      showUnlisted: isCurated,
      ...availabilityParams,
    })

    if (isExperienceEnabled && showOnlyCurated) return lists[hotelExperiencesListKey] ?? EmptyExperienceList

    const transferListKey = getExperienceListKey({
      latitude: geoCoords.latitude,
      longitude: geoCoords.longitude,
      currencyCode,
      categoryCodes: [EXP_CATEGORY_AIRPORT_TRANSFER_ID, EXP_CATEGORY_AIRPORT_LOUNGE_ID],
      ...availabilityParams,
    })

    const postPurchaseListKey = getExperienceListKey({
      latitude: geoCoords.latitude,
      longitude: geoCoords.longitude,
      currencyCode,
      postPurchaseOnly: true,
      ...availabilityParams,
    })

    const fallbackListKey = getExperienceListKey({
      latitude: geoCoords.latitude,
      longitude: geoCoords.longitude,
      currencyCode,
      postPurchaseOnly: false,
      ...availabilityParams,
    })

    const transferList = lists[transferListKey] ?? EmptyExperienceList
    const postPurchaseList = lists[postPurchaseListKey] ?? EmptyExperienceList
    const fallbackList = lists[fallbackListKey] ?? EmptyExperienceList
    const hotelExperiencesList = lists[hotelExperiencesListKey] ?? { ...EmptyExperienceList, fetching: isCurated }

    return {
      fetching: isExperienceEnabled && (postPurchaseList.fetching || fallbackList.fetching || transferList.fetching || hotelExperiencesList.fetching),
      ids: take(
        unique([
          ...hotelExperiencesList.ids,
          ...transferList.ids,
          ...postPurchaseList.ids,
          ...fallbackList.ids,
        ]),
        // take transfer ids + curated for offer + max of 10 of others (that aren't in the first lists)!@@
        EXPERIENCE_IN_FLOW_MAX_SHOWN + transferList.ids.length + hotelExperiencesList.ids.length,
      ),
    }
  },
)

export const getExperiencesViews = createSelector(
  (state: App.State) => state.experience.experiences,
  (state: App.State) => getExperienceOfferList(state),
  (state: App.State) => getLeOfferInCart(state),
  (state: App.State) => getBedbankOfferInCart(state),
  (experiences, expList, offer, bedbankOffer): Array<App.ExperienceBookingView> => {
    return expList.ids.map(id => experiences[id]).filter(Boolean).map(exp =>
      mapExperienceToBookingView(exp, undefined, offer?.property ?? bedbankOffer?.property),
    )
  },
)

export const getAllPurchasableExperiences = createSelector(
  (state: App.State) => getExperiencesViews(state),
  (state: App.State) => getExperienceItems(state),
  (state: App.State) => getAccommodationStartDate(state),
  (experienceViews, expItems, from): Array<App.ExperienceBookingView> => {
    const cartItemIdSet = new Set(expItems.map(item => item.experienceId))
    return experienceViews
      .filter(item => {
        if (item.originalExperience.expirationDate && from) {
          return moment(item.originalExperience.expirationDate).isAfter(moment(from))
        }
        return true
      })
      .map(item => ({
        ...item,
        added: cartItemIdSet.has(item.id),
      }))
  },
)

export const getAllPurchasableExperiencesWithoutTransfers = createSelector(
  (state: App.State) => getAllPurchasableExperiences(state),
  (experiences) => experiences.filter(experience => {
    const withNoCategory = !experience.categories.length
    const withNoTransferCategory = !experience.categories.some(category => category.parentId === EXPERIENCE_TRANSFER_ID)
    const isNotSetTransfer = !experience.features?.transfer

    return withNoCategory || (withNoTransferCategory && isNotSetTransfer)
  }),
)

export const getExperienceRefundDetails = createSelector(
  (state: App.State) => state.experience.refundDetails,
  (state: App.State) => state.experience.cancellingExperiences,
  (state: App.State) => state.order.merchantFeeDetails,
  (experiencesRefundDetails, cancellingExperiences, merchantFeeDetails) => {
    let total = 0
    let itemToCancell = { amount: 0, fee: 0 }

    const refundDetails = Object.values(experiencesRefundDetails)

    if (refundDetails?.length) {
      total = sum(refundDetails.map(refund => {
        const merchantFeeItem = getMerchantFeeByOrderItem(merchantFeeDetails, refund.orderItemId)
        const refundableMerchantFee = merchantFeeItem?.metadata.data.shouldAutoRefund ? parseFloat(merchantFeeItem?.amount) : 0
        return refund.refundAmount + refundableMerchantFee
      }))
    }

    if (refundDetails?.length && cancellingExperiences?.length) {
      const items = refundDetails.filter(refund => cancellingExperiences.some(item => item.id === refund.orderItemId))
      itemToCancell = {
        amount: sum(items.map(refund => {
          const merchantFeeItem = getMerchantFeeByOrderItem(merchantFeeDetails, refund.orderItemId)
          const refundableMerchantFee = merchantFeeItem?.metadata.data.shouldAutoRefund ? parseFloat(merchantFeeItem?.amount) : 0
          return refund.refundAmount + refundableMerchantFee
        })),
        fee: sum(items.map(refund => {
          const merchantFeeItem = getMerchantFeeByOrderItem(merchantFeeDetails, refund.orderItemId)
          const nonRefundableMerchantFee = merchantFeeItem && !merchantFeeItem.metadata.data.shouldAutoRefund ? parseFloat(merchantFeeItem?.amount) : 0
          return refund.refundFee + nonRefundableMerchantFee
        })),
      }
    }

    return {
      total,
      itemToCancell,
    }
  },
)

export const getChangeDatesExperiencesOutsideHotelStay = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => getAccommodationStartDate(state),
  (state: App.State) => getAccommodationEndDate(state),
  (order, startDate, endDate): Array<App.OrderExperienceItem> => {
    if (!order) return []
    const experiences = order.experienceItems.filter((e) => isExperienceItemUpcoming(e, order))

    return experiences.filter(item => {
      if (startDate && endDate && moment(item.date).isBetween(startDate, endDate, null, '[]')) {
        return false
      }

      if (item.complimentary) {
        return false
      }

      return true
    })
  },
)

export const getChangeDatesTransfersOutsideHotelStay = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => getAccommodationStartDate(state),
  (state: App.State) => getAccommodationEndDate(state),
  (order, startDate, endDate): Array<App.OrderTransferItem> => {
    if (!order) return []

    const transfers = order.transferItems.filter(isTransferItemUpcoming)

    return transfers.filter(item => {
      if (startDate && endDate && moment(item.date).isBetween(startDate, endDate, null, '[]')) {
        return false
      }

      if (item.complimentary) {
        return false
      }

      return true
    })
  },
)

export const getOneClickExperienceTransfer = createSelector(
  (state: App.State) => getAllPurchasableExperiences(state),
  (state: App.State) => getTransferItems(state),
  (state: App.State) => state.experience.experiences,
  (purchasableExperiences, checkoutTransferItems, allExperiences): App.ExperienceBookingView | undefined => {
    if (isNonEmptyArray(checkoutTransferItems) && !isEmptyObject(allExperiences)) {
      const experienceTransferId = checkoutTransferItems[0].experienceId
      const experienceTransferOffer = allExperiences[experienceTransferId]
      return mapExperienceToBookingView(experienceTransferOffer)
    }

    const transfers = Object.values(purchasableExperiences)?.filter(experience => experience.categories.some(category => category.parentId === EXPERIENCE_TRANSFER_ID))
    if (!transfers.length) return

    const preferredTransfers = transfers.filter(transfer => !!transfer.features?.transfer?.preferredTransfer)
    if (preferredTransfers.length) {
      return min(preferredTransfers, (transfer) => transfer.price)
    }

    return min(transfers, (transfer) => transfer.price)
  },
)

export const isAccomodationWithNoIncludedTransfer = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => state.checkout.cart.items,
  (accommodationOfferView, checkoutItems) => {
    const containsAccommodation = checkoutItems.some(item => isLEHotelItem(item) || isBedbankItem(item))
    if (!containsAccommodation) return false

    const containsIncludedTransfer = accommodationOfferView.data.some((offerView) => {
      return !!offerView.itemViews.some((itemView: App.Checkout.AccommodationItemView) => {
        if (isBedbankAccommodationItemView(itemView)) {
          const inclusions = itemView.inclusions ?? []
          return inclusions.some(
            inclusion => inclusion.description.toLocaleLowerCase().includes('transfer'),
          )
        }

        if (isLEHotelAccommodationItemView(itemView)) {
          const inclusions = itemView.inclusions ?? []
          const bonusInclusions = itemView?.bonusInclusions ?? []
          return [...inclusions, ...bonusInclusions].some(
            inclusion => inclusion.parentCategory === 'Transport' ||
            inclusion.description.toLocaleLowerCase().includes('transfer'),
          )
        }

        return false
      })
    })

    return !containsIncludedTransfer
  },
)

/**
 * One click transfer is enabled when:
 * - There is a transfer available for the accommodation location
 * - The user has an accommodation in the cart
 * - The accommodation doesn't have a transfer included in the offer
 * - The accomodation is not located in Fiji due to logistical complications
 *   In this region, transfers require both a vehicle and a water ferry, making the process less straightforward
 */
export const isOneClickExperienceTransferEnabled = createSelector(
  (state: App.State) => getOneClickExperienceTransfer(state),
  (state: App.State) => isAccomodationWithNoIncludedTransfer(state),
  (state: App.State) => hasFijiHotelInCart(state),
  (oneClickTransfer, isAccomodationWithNoIncludedTransfer, hasFijiHotelInCart) =>
    !!oneClickTransfer && isAccomodationWithNoIncludedTransfer && !hasFijiHotelInCart,
)

export const getExperienceTransferBestOptions = createSelector(
  (state: App.State) => state.experience.experienceTransferOptions,
  (state: App.State) => getDefaultTransfers(state, getOneClickExperienceTransfer(state)),
  (transferOptions, defaultTransfers) => {
    const [toHotelOptions, toAirportOptions] = [
      Object.values(transferOptions).flatMap(option => option?.transfers ?? []).filter(transfer => transfer?.transferTypes.includes('AIRPORT-TO-HOTEL')),
      Object.values(transferOptions).flatMap(option => option?.transfers ?? []).filter(transfer => transfer?.transferTypes.includes('HOTEL-TO-AIRPORT')),
    ]

    const { bestTransfer: bestToHotelOption } = findBestTransfer(defaultTransfers.toHotel, toHotelOptions)
    const { bestTransfer: bestToAirportOption } = findBestTransfer(defaultTransfers.toAirport, toAirportOptions)

    return {
      bestToHotelOption,
      bestToAirportOption,
    }
  },
)
