import { createSelector } from 'reselect'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getExperienceItemsView } from 'checkout/selectors/view/experience'

const getCheckoutExperiencePointsCalculationRequests = createSelector(
  (state: App.State) => getExperienceItemsView(state),
  (experienceViews): Array<App.LuxLoyaltyDefaultPointsEarnCalculationRequest> => {
    if (!experienceViews.hasRequiredData) return EmptyArray

    return experienceViews.data.map((itemView) => ({
      price: itemView.totals.price,
      luxLoyaltyProductType: 'experience',
      itemId: itemView.item.itemId,
    }))
  },
)

export default getCheckoutExperiencePointsCalculationRequests
