import { EmptyArray } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getHotelItemViews from '../view/getHotelItemViews'

const getCheckoutItemViewsWithLoyaltyRoomUpgradeAvailable = createSelector(
  (state: App.State) => getHotelItemViews(state),
  (hotelOfferViews): Array<App.Checkout.LEAccommodationItemView> => {
    if (!hotelOfferViews.hasRequiredData) return EmptyArray

    const itemViews = hotelOfferViews.data.flatMap(offerView => offerView.itemViews)
    if (!itemViews.length) return EmptyArray

    const itemViewsWithUpgradeAvailable = itemViews.filter(itemView => !!itemView.loyaltyUpgrade?.targetPackage)
    if (!itemViewsWithUpgradeAvailable.length) return EmptyArray

    return itemViewsWithUpgradeAvailable
  },
)

export default getCheckoutItemViewsWithLoyaltyRoomUpgradeAvailable
