import getCheckoutItemViewsWithLoyaltyRoomUpgradeAvailable from 'checkout/selectors/luxLoyalty/getCheckoutItemViewsWithLoyaltyRoomUpgradeAvailable'
import getHotelItemViews from 'checkout/selectors/view/getHotelItemViews'
import getLoyaltyBenefitAvailability from 'luxLoyalty/lib/determineLoyaltyBenefitAvailability'
import getLoyaltyRoomUpgradeBenefit from 'luxLoyalty/selectors/getLoyaltyRoomUpgradeBenefit'
import { createSelector } from 'reselect'

const getCanRedeemRoomUpgradeLoyaltyBenefit = createSelector(
  (state: App.State) => getLoyaltyRoomUpgradeBenefit(state),
  (state: App.State) => getCheckoutItemViewsWithLoyaltyRoomUpgradeAvailable(state),
  (state: App.State) => getHotelItemViews(state),
  (
    roomUpgradeBenefit,
    itemViewsWithLoyaltyUpgradeAvailable,
    hotelOfferViews,
  ): boolean => {
    if (!roomUpgradeBenefit || !getLoyaltyBenefitAvailability(roomUpgradeBenefit)) return false
    /**
     * Ideally `!!itemViewsWithLoyaltyUpgradeAvailable.length` should be accurate enough,
     * but we're not supporting multi accommodation items yet.
     * For that reason, we only enable the benefit section if there's only **one** accommodation item.
     */
    return hotelOfferViews.data.length === 1 && itemViewsWithLoyaltyUpgradeAvailable.length === 1
  },
)

export default getCanRedeemRoomUpgradeLoyaltyBenefit
