import { createSelector } from 'reselect'
import getCheckoutAccommodationPointsCalculationRequests from './getCheckoutAccommodationPointsCalculationRequests'
import getCheckoutLuxPlusPointsCalculationRequests from './getCheckoutLuxPlusPointsCalculationRequests'
import getCheckoutInsurancePointsCalculationRequests from './getCheckoutInsurancePointsCalculationRequests'
import { EmptyArray } from 'lib/array/arrayUtils'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'
import getCheckoutVillasPointsCalculationRequests from './getCheckoutVillasPointsCalculationRequests'
import getCheckoutTourExperiencePointsCalculationRequests
  from 'luxLoyalty/selectors/checkout/pointsCalculation/getCheckoutTourExperiencePointsCalculationRequests'
import getCheckoutFlightsPointsCalculationRequests from './getCheckoutFlightsPointsCalculationRequest'
import getCheckoutExperiencePointsCalculationRequests from './getCheckoutExperiencePointsCalculationRequests'
import getCheckoutCarHirePointsCalculationRequests from './getCheckoutCarHirePointsCalculationRequests'
import getCheckoutLoungePassPointsCalculationRequest from './getCheckoutLoungePassPointsCalculationRequest'

const getCheckoutPointsCalculationRequests = createSelector(
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => getCheckoutAccommodationPointsCalculationRequests(state),
  (state: App.State) => getCheckoutTourExperiencePointsCalculationRequests(state),
  (state: App.State) => getCheckoutVillasPointsCalculationRequests(state),
  (state: App.State) => getCheckoutLuxPlusPointsCalculationRequests(state),
  (state: App.State) => getCheckoutInsurancePointsCalculationRequests(state),
  (state: App.State) => getCheckoutFlightsPointsCalculationRequests(state),
  (state: App.State) => getCheckoutExperiencePointsCalculationRequests(state),
  (state: App.State) => getCheckoutCarHirePointsCalculationRequests(state),
  (state: App.State) => getCheckoutLoungePassPointsCalculationRequest(state),
  // Add other verticals here
  (
    postPurchase,
    ...allCalculationRequests
  ): Array<App.LuxLoyaltyPointsEarnCalculationRequest> => {
    // TODO - update when we revisit post purchase
    if (postPurchase) return EmptyArray

    // In svc-lux-loyalty we have to aggressively cache due to our rate 3rd party rate limit agreement.
    // Checkout requests bypass the cache to get a more accurate points calculation result
    const allRequestsBypassingCache: Array<App.LuxLoyaltyPointsEarnCalculationRequest> = allCalculationRequests.flat().map(request => ({
      ...request,
      bypassCache: true,
    }))

    return allRequestsBypassingCache
  },
)

export default getCheckoutPointsCalculationRequests
