import OfferInclusionsList from 'components/Common/Inclusions/OfferInclusionsList'
import Modal from 'components/Luxkit/Modal/Modal'
import Group from 'components/utils/Group'
import ModalContext from 'contexts/ModalContext'
import { useOfferInclusions } from 'hooks/useOfferInclusions'
import LuxPlusInclusions from 'luxPlus/components/LuxPlusInclusions'
import React, { useCallback, useContext, useMemo } from 'react'
import SearchTileStackAmenitiesList from '../shared/SearchTileStackAmenitiesList'
import SearchTileStackInclusionsList from '../shared/SearchTileStackInclusionsList'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import useLuxLoyaltyUserBenefit from 'luxLoyalty/hooks/useLuxLoyaltyUserBenefit'
import LuxLoyaltyRoomUpgradeBenefitLabel from 'luxLoyalty/components/LuxLoyaltyRoomUpgradeBenefitLabel'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'

const MAX_LUX_PLUS_INCLUSIONS_TO_DISPLAY = 5
const MAX_REGULAR_INCLUSIONS_TO_DISPLAY = 2
const MAX_TOTAL_INCLUSIONS_TO_DISPLAY = MAX_LUX_PLUS_INCLUSIONS_TO_DISPLAY + MAX_REGULAR_INCLUSIONS_TO_DISPLAY

interface Props {
  bestPackage?: App.HotelPackage
  filters?: App.OfferListFilters
  offer: App.HotelOffer | App.HotelOfferSummary
  offerMetaData?: App.OfferListMetaData
}

function HotelSearchTileFeatures({
  bestPackage,
  filters,
  offer,
  offerMetaData,
}: Props) {
  const loyaltyUpgradeBenefit = useLuxLoyaltyUserBenefit('hotel_upgrade')
  const hasRoomUpgradesAvailable = !!(loyaltyUpgradeBenefit?.remaining || loyaltyUpgradeBenefit?.unlimited)

  const { luxPlusInclusions } = useOfferInclusions(
    bestPackage,
    {
      checkIn: filters?.checkIn,
      checkOut: filters?.checkOut,
    },
  )

  const luxPlusSearchTileInclusions = useMemo<Array<App.PackageInclusion>>(() => {
    if (offer.luxPlusTileInclusions?.length) {
      return offer.luxPlusTileInclusions
    }
    return luxPlusInclusions
  }, [luxPlusInclusions, offer.luxPlusTileInclusions])

  const [
    featuresType,
    regularSearchTileFeatures,
    regularSearchTileFeaturesTitle,
  ] = useMemo(() => {
    if (offer.tileInclusions?.length) {
      return [
        'inclusions',
        offer.tileInclusions,
        offer.tileInclusionsHeading,
      ] as const
    }

    if (offer.lowestPricePackage?.inclusions?.length) {
      return [
        'inclusions',
        offer.lowestPricePackage.inclusions.filter((inclusion) => inclusion.displayContext === 'Rate'),
        'Select packages include:',
      ] as const
    }

    if (offer.lowestPricePackage?.roomType.amenities.length) {
      return [
        'amenities',
        offer.lowestPricePackage.roomType.amenities,
        'Select packages only:',
      ] as const
    }

    const pkgWithInclusions = offer.packages.find((p) => p.inclusions?.length)
    return [
      'amenities',
      pkgWithInclusions?.inclusions?.filter((inclusion) => inclusion.displayContext === 'Rate'),
      'Select packages only:',
    ] as const
  }, [offer])

  const maxLuxPlusInclusionsToDisplay = useMemo<number>(() => {
    if (!regularSearchTileFeatures?.length) return MAX_TOTAL_INCLUSIONS_TO_DISPLAY
    return Math.max(MAX_TOTAL_INCLUSIONS_TO_DISPLAY - regularSearchTileFeatures.length, MAX_LUX_PLUS_INCLUSIONS_TO_DISPLAY)
  }, [regularSearchTileFeatures])

  const maxRegularInclusionsToDisplay = useMemo<number>(() => {
    if (!luxPlusSearchTileInclusions.length) return MAX_TOTAL_INCLUSIONS_TO_DISPLAY
    return Math.max(MAX_TOTAL_INCLUSIONS_TO_DISPLAY - luxPlusSearchTileInclusions.length, MAX_REGULAR_INCLUSIONS_TO_DISPLAY)
  }, [luxPlusSearchTileInclusions])

  const offerUrl = useOfferUrl(offer, {
    filters,
    offerLinkIncludesFilters: true,
    bundledOfferId: offerMetaData?.bundledOfferId,
  })

  const showModal = useContext(ModalContext)
  const openAllInclusionsModal = useCallback(() => {
    showModal(<Modal
      title={offer.property.name}
      primaryActionText="View offer"
      primaryActionTo={offerUrl}
    >
      <Group direction="vertical" gap={12}>
        <OfferInclusionsList type="lux-plus" inclusions={luxPlusSearchTileInclusions} />
        {regularSearchTileFeatures && <OfferInclusionsList inclusions={regularSearchTileFeatures} />}
      </Group>
    </Modal>)
  }, [showModal, offer.property.name, offerUrl, luxPlusSearchTileInclusions, regularSearchTileFeatures])

  return <Group className={HotelSearchTileFeatures.name} direction="vertical" gap={12} noDisplayWhenEmpty>
    {!!luxPlusSearchTileInclusions?.length && <LuxPlusInclusions
      luxPlusInclusions={luxPlusSearchTileInclusions}
      accommodationOffer={offer}
      hideUpsellModal={!!offer.luxPlusTileInclusions?.length}
      showMinStayDescription
      truncateAfter={maxLuxPlusInclusionsToDisplay}
      onShowMoreClick={openAllInclusionsModal}
    />}
    {offer.luxLoyalty.hasUpgradablePackages && hasRoomUpgradesAvailable && <LuxLoyaltyRoomUpgradeBenefitLabel />}
    {!!regularSearchTileFeatures?.length && <>
      {featuresType === 'inclusions' && <SearchTileStackInclusionsList
        inclusions={regularSearchTileFeatures}
        inclusionsTitle={regularSearchTileFeaturesTitle}
        truncateAfter={maxRegularInclusionsToDisplay}
        onShowMoreClick={openAllInclusionsModal}
        // we want to show "Select packages only" label on search tile inclusions heading for SPP offers (which are LPC)
        // see https://aussiecommerce.atlassian.net/browse/HP-1012
        showSelectPackageOnlyLabel={offer.type === OFFER_TYPE_ALWAYS_ON}
      />}
      {featuresType === 'amenities' && <SearchTileStackAmenitiesList
        amenities={regularSearchTileFeatures}
        amenitiesTitle={regularSearchTileFeaturesTitle}
      />}
    </>}
  </Group>
}

export default HotelSearchTileFeatures
