import { capitalise } from 'lib/string/stringUtils'
import { createSelector } from 'reselect'
import getInsuranceItemsView from './getInsuranceItemsView'
import moment from 'moment'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { checkoutContainsFlighsWithEmbeddedInsurance, getFlightItems } from './flights'

function getInsuranceBreakdownItem(item: App.Checkout.InsuranceItemView): App.Checkout.InsuranceItemBreakdownView {
  return {
    itemId: item.item.itemId,
    title: item.policyNames.length ? '' : item.name,
    price: item.totals.price,
    memberPrice: item.totals.memberPrice,
    itemType: 'insurance',
    additionalInfoText: [
      ...item.policyNames,
      `${moment(item.startDate).format(DMY_CASUAL_FORMAT)} - ${moment(item.endDate).format(DMY_CASUAL_FORMAT)}`,
      `Covered in: ${item.destinationCountries.join(', ')}`,
    ],
    additionalElements: [],
    taxesAndFees: item.totals.taxesAndFees,
    mobileAppPrice: item.mobileAppPrice,
  }
}

function formatInsuredFlightsText(containsFlighsWithEmbeddedInsurance: boolean, flights: Array<App.Checkout.FlightItem>) {
  if (!containsFlighsWithEmbeddedInsurance || !flights.length) return
  const flight = flights[0]

  const flightsWithInsurance = flight.flights.reduce((acc, flight, index) => {
    if (flight.fareFamily?.withEmbeddedInsurance) {
      acc.add(index + 1)
    }
    return acc
  }, new Set<number>())

  const totalFlights = flight.flights.length
  const insuredFlights = [...flightsWithInsurance]

  if (insuredFlights.length === totalFlights) {
    return 'all your flights'
  }

  if (insuredFlights.length === 1) {
    return `flight ${insuredFlights[0]}`
  }

  if (insuredFlights.length === 2) {
    return `flight ${insuredFlights[0]} and flight ${insuredFlights[1]}`
  }

  // More than 2 flights
  const lastFlight = insuredFlights.pop()
  return `(flight ${insuredFlights.join(', flight ')}, and flight ${lastFlight})`
}

const getInsuranceBreakdownView = createSelector(
  (state: App.State) => getInsuranceItemsView(state),
  (state: App.State) => state.geo.insuranceProductName,
  (state: App.State) => checkoutContainsFlighsWithEmbeddedInsurance(state),
  (state: App.State) => getFlightItems(state),
  (viewWithStatus, insuranceName, containsFlighsWithEmbeddedInsurance, flights): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) {
      return {
        hasRequiredData: viewWithStatus.hasRequiredData,
        data: [],
      }
    }

    const breakdownViews = viewWithStatus.data.map((view): App.Checkout.PriceBreakdownView => {
      const breakdownItem = getInsuranceBreakdownItem(view)

      if (view.item.insuranceType === 'cfmr') {
        return {
          title: containsFlighsWithEmbeddedInsurance ? 'Luxury Escapes Protect' : 'Cancellation ' + capitalise(insuranceName),
          price: view.totals.price + (view.totals.mobileAppDiscount?.amount ?? 0),
          memberPrice: view.totals.memberPrice,
          additionalInfoText: containsFlighsWithEmbeddedInsurance ? [`Cancellation Protection included with ${formatInsuredFlightsText(containsFlighsWithEmbeddedInsurance, flights)} – 100% refund for unforeseen events`] : [],
          items: [breakdownItem],
          breakdownItemType: 'bookingProtection',
          alwaysShowItemPrice: !!view.totals.price,
          mobileAppDiscount: view.totals.mobileAppDiscount,
        }
      } else {
        return {
          title: 'Travel ' + capitalise(insuranceName),
          price: view.totals.price + (view.totals.mobileAppDiscount?.amount ?? 0),
          memberPrice: view.totals.memberPrice,
          additionalInfoText: [],
          items: [breakdownItem],
          breakdownItemType: 'insurance',
          mobileAppDiscount: view.totals.mobileAppDiscount,
        }
      }
    })
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: breakdownViews,
    }
  },
)

export default getInsuranceBreakdownView
