import { createSelector } from 'reselect'
import getCanRedeemTransferLoyaltyBenefit from './getCanRedeemTransferLoyaltyBenefit'
import getCanRedeemLoungePassLoyaltyBenefit from './getCanRedeemLoungePassLoyaltyBenefit'
import getCanRedeemRoomUpgradeLoyaltyBenefit from './getCanRedeemRoomUpgradeLoyaltyBenefit'

const getCanRedeemCheckoutLoyaltyBenefits = createSelector(
  (state: App.State) => getCanRedeemTransferLoyaltyBenefit(state),
  (state: App.State) => getCanRedeemLoungePassLoyaltyBenefit(state),
  (state: App.State) => getCanRedeemRoomUpgradeLoyaltyBenefit(state),
  (canRedeemTransfers, canRedeemLoungePasses, canRedeemRoomUpgradeLoyaltyBenefit): boolean => {
    return canRedeemLoungePasses || canRedeemTransfers || canRedeemRoomUpgradeLoyaltyBenefit
  },
)

export default getCanRedeemCheckoutLoyaltyBenefits
