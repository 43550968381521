import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS, CLEAR_LUX_LOYALTY_ENROLMENT } from 'actions/actionConstants'
import {
  USER_LOGOUT,
  ENROL_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY,
  FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION,
  SUBMIT_LUX_LOYALTY_POINTS_BURN_CALCULATION,
  FETCH_LUX_LOYALTY_CONFIG,
  FETCH_LUX_LOYALTY_ACCOUNT_ACTIVITY,
} from 'actions/apiActionConstants'

export const initialLuxLoyaltyState: App.LuxLoyaltyState = {
  enrolment: undefined,
  account: { initial: true },
  eligibilityTier: { initial: true },
  pointsEarnCalculations: {},
  pointsBurnCalculations: {},
  config: undefined,
  configError: undefined,
  fetchingConfig: false,
  accountActivity: { initial: true },
}

const apiRequests = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: () => ({
    account: {
      fetching: true as const,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT_ACTIVITY]: () => ({
    accountActivity: {
      fetching: true as const,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: () => ({
    account: {
      fetching: true as const,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: () => ({
    eligibilityTier: {
      fetching: true as const,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION]: (state, action) => ({
    pointsEarnCalculations: {
      ...state.pointsEarnCalculations,
      [action.pointsEarnCalculationKey]: { fetching: true },
    },
  }),
  [SUBMIT_LUX_LOYALTY_POINTS_BURN_CALCULATION]: (state, action) => {
    return {
      pointsBurnCalculations: {
        ...state.pointsBurnCalculations,
        [action.pointsBurnCalculationKey]: { fetching: true },
      },
    }
  },
  [FETCH_LUX_LOYALTY_CONFIG]: () => {
    return {
      fetchingConfig: true,
    }
  },
})

const apiSuccesses = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      data: action.data,
      fetching: false as const,
      error: undefined,
    },
    enrolment: action.enrolment,
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      data: action.data,
      fetching: false as const,
      error: undefined,
    },
  }),
  [USER_LOGOUT]: () => initialLuxLoyaltyState,
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: (_state, action) => ({
    eligibilityTier: {
      data: action.data,
      fetching: false as const,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION]: (state, action) => ({
    pointsEarnCalculations: {
      ...state.pointsEarnCalculations,
      [action.pointsEarnCalculationKey]: { data: action.data },
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT_ACTIVITY]: (_state, action) => ({
    accountActivity: {
      data: action.data,
      fetching: false as const,
      error: undefined,
    },
  }),
  [SUBMIT_LUX_LOYALTY_POINTS_BURN_CALCULATION]: (state, action) => {
    return {
      pointsBurnCalculations: {
        ...state.pointsBurnCalculations,
        [action.pointsBurnCalculationKey]: { data: action.data },
      },
    }
  },
  [FETCH_LUX_LOYALTY_CONFIG]: (state, action) => {
    return {
      config: action.data,
      fetchingConfig: false,
    }
  },
})

const apiFailures = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      fetching: false as const,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      fetching: false as const,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: (_state, action) => ({
    eligibilityTier: {
      fetching: false as const,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION]: (state, action) => ({
    pointsEarnCalculations: {
      ...state.pointsEarnCalculations,
      [action.pointsEarnCalculationKey]: { error: action.error },
    },
  }),
  [SUBMIT_LUX_LOYALTY_POINTS_BURN_CALCULATION]: (state, action) => {
    return {
      pointsBurnCalculations: {
        ...state.pointsBurnCalculations,
        [action.pointsBurnCalculationKey]: { error: action.error },
      },
    }
  },
  [FETCH_LUX_LOYALTY_ACCOUNT_ACTIVITY]: (_state, action) => ({
    accountActivity: {
      fetching: false as const,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_CONFIG]: (state, action) => {
    return {
      configError: action.error,
      fetchingConfig: false,
    }
  },
})

export default createReducer<App.LuxLoyaltyState>(initialLuxLoyaltyState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CLEAR_LUX_LOYALTY_ENROLMENT]: () => ({
    enrolment: undefined,
  }),
})
