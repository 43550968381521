import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'
import { OfferPackageOptionView } from 'selectors/offerPage/offerPageSelectors'
import getHotelEncryptedMargin from 'luxLoyalty/lib/margin/getHotelEncryptedMargin'

interface BaseAdditionalOptions {
  skip?: boolean;
  luxLoyaltyProductType?: App.LuxLoyaltyDefaultPointsEarnCalculationRequest['luxLoyaltyProductType'];
}

interface BasePricingOptions {
  price?: number;
  memberPrice?: number;
}

interface GenerateLuxLoyaltyPointsCalculatorHotelAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {
  packageView?: OfferPackageOptionView;
  pkg?: App.HotelPackage;
}

export function generateLuxLoyaltyPointsCalculatorHotelOptions(
  offer: App.Offer | App.OfferSummary | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorHotelAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    packageView,
    pkg,
    skip,
    price,
    memberPrice,
  } = options
  const luxLoyaltyProductType = getLuxLoyaltyProductType(offer)

  if (
    !offer ||
    (!packageView && !pkg) ||
    (!luxLoyaltyProductType || (luxLoyaltyProductType !== 'hotel_flash' && luxLoyaltyProductType !== 'hotel_lpc')) ||
    skip
  ) return

  return {
    price: price ?? packageView?.pricing?.price ?? 0,
    memberPrice: memberPrice ?? packageView?.memberPricing?.price ?? 0,
    luxLoyaltyProductType,
    mx: getHotelEncryptedMargin(offer.type, packageView?.package ?? pkg),
    offerId: offer.id,
    packageId: packageView?.package.id ?? pkg?.id ?? '',
  }
}

interface GenerateLuxLoyaltyPointsCalculatorTourAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

interface GenerateLuxLoyaltyPointsCalculatorBedbankAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

export function generateLuxLoyaltyPointsCalculatorBedbankOptions(
  rate: App.BedbankRate | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorBedbankAdditionalOptions | undefined,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    price,
    memberPrice,
  } = options ?? {}

  if (skip) return

  return {
    price: price ?? 0,
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: 'hotel_lpp_eps',
    mx: rate?.mx,
  }
}

interface GenerateLuxLoyaltyPointsCalculatorVillasAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {
  pkg: App.HotelPackage | undefined;
}

export function generateLuxLoyaltyPointsCalculatorVillasOptions(
  offer: App.VillaOffer | App.VillaOfferSummary | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorVillasAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    pkg,
    skip,
    price,
    memberPrice,
  } = options ?? {}

  if (skip || !offer || !pkg) return

  return {
    price: price ?? 0,
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: 'rental',
    mx: pkg.roomRate.mx,
    offerId: offer.id,
    packageId: pkg.id,
  }
}

interface FlightPricingOptions {
  flightPrice?: number;
}

interface GenerateLuxLoyaltyPointsCalculatorFlightAdditionalOptions extends BaseAdditionalOptions, FlightPricingOptions {
  packageView?: OfferPackageOptionView;
}

export function generateLuxLoyaltyPointsCalculatorBundledFlightOptions(
  offer: App.Offer | App.OfferSummary | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorFlightAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    packageView,
    flightPrice,
  } = options ?? {}

  if (
    skip ||
    !offer?.offerFlightsEnabled
  ) return

  return {
    price: flightPrice ?? packageView?.flightPrice ?? 0,
    luxLoyaltyProductType: 'dom_economy',
  }
}

interface GenerateLuxLoyaltyPointsCalculatorInsuranceAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

export function generateLuxLoyaltyPointsCalculatorInsuranceOptions(
  options?: GenerateLuxLoyaltyPointsCalculatorInsuranceAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    price,
    memberPrice,
    luxLoyaltyProductType,
  } = options ?? {}

  if (skip) return

  return {
    price: price ?? 0,
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: luxLoyaltyProductType ?? 'insurance',
    // Only used in checkout where we need to bypass the cache
    // See - getCheckoutPointsCalculationRequests
    bypassCache: true,
  }
}

interface GenerateLuxLoyaltyPointsCalculatorExperienceAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

export function generateLuxLoyaltyPointsCalculatorExperienceOptions(
  offer: App.ExperienceOffer | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorExperienceAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    memberPrice,
  } = options ?? {}

  if (skip) return

  return {
    price: offer?.price ?? 0,
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: 'experience',
  }
}

interface GenerateLuxLoyaltyPointsCalculatorTourAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

export function generateLuxLoyaltyPointsCalculatorTourOptions(
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorTourAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    price,
    memberPrice,
  } = options
  const luxLoyaltyProductType = getLuxLoyaltyProductType(offer)

  if (
    !offer ||
    skip
  ) return

  return {
    price: price ?? 0,
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: luxLoyaltyProductType as App.LuxLoyaltyTourProductType,
  }
}

interface GenerateLuxLoyaltyPointsCalculatorTourExperienceAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {}

export function generateLuxLoyaltyPointsCalculatorTourExperienceOptions(
  tourExperience: App.Tours.TourExperience,
  options?: GenerateLuxLoyaltyPointsCalculatorTourExperienceAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
  } = options ?? {}

  if (skip) return

  return {
    price: tourExperience.price,
    luxLoyaltyProductType: 'tour_optional_extra',
  }
}

interface GenerateLuxLoyaltyPointsCalculatorCarHireAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {
  selectedInsurance?: number;
}

export function generateLuxLoyaltyPointsCalculatorCarHireOptions(
  offer: App.CarHireOffer | undefined,
  options: GenerateLuxLoyaltyPointsCalculatorCarHireAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const { skip, memberPrice } = options ?? {}

  if (skip) return

  // At the moment only apply loyalty points to the pay now amount
  const price = offer?.rateOptions.reduce((acc, rateOption) => {
    return acc + (rateOption.payNowAmount || 0)
  }, 0)

  return {
    price: (price ?? 0) + (options.selectedInsurance ?? 0),
    memberPrice: memberPrice ?? 0,
    luxLoyaltyProductType: 'car_hire',
  }
}

interface GenerateLuxLoyaltyPointsCalculatorCruiseAdditionalOptions extends BaseAdditionalOptions, BasePricingOptions {
  bookingPrice?: number;
}

export function generateLuxLoyaltyPointsCalculatorCruiseOptions(
  offer: App.CruiseOffer | undefined,
  options?: GenerateLuxLoyaltyPointsCalculatorCruiseAdditionalOptions,
): App.LuxLoyaltyPointsEarnCalculationRequest | undefined {
  const {
    skip,
    bookingPrice,
  } = options ?? {}

  const productType = getLuxLoyaltyProductType(offer)

  if (skip || !productType || !offer) return

  const price = bookingPrice || offer.mainDepartureDetails.lowestOverallPriceDetails.total || 0

  return {
    price,
    luxLoyaltyProductType: productType as App.CruiseLoyaltyProductType,
  }
}
