import getItemViewTotalsPrice from 'checkout/lib/utils/getItemViewTotalsPrice'
import getItemViewAppliedLoyaltyUpgrade from 'checkout/lib/utils/luxLoyalty/getItemViewAppliedLoyaltyUpgrade'
import { EmptyMap } from 'lib/array/arrayUtils'
import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getAllItemViews from '../view/getAllItemViews'
import { checkoutWithMemberPrice, selectShouldUseBookingProtectionMemberPrice, selectShouldUseInsuranceMemberPrice } from '../view/luxPlusSubscription'

const getCheckoutLuxLoyaltyItemsPriceInCurrency = createSelector(
  (state: App.State) => getAllItemViews(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => selectShouldUseInsuranceMemberPrice(state),
  (state: App.State) => selectShouldUseBookingProtectionMemberPrice(state),
  (
    allItemViews,
    canCheckoutWithMemberPrice,
    shouldUseInsuranceMemberPrice,
    shouldUseBookingProtectionMemberPrice,
  ): Map<string, number> => {
    if (!allItemViews.hasRequiredData) return EmptyMap

    const priceByItem: Map<string, number> = new Map()

    allItemViews.data.forEach((itemView) => {
      const view = itemView.data

      if (view.luxLoyaltyProductType) {
        let priceInCurrency: number

        switch (view.luxLoyaltyProductType) {
          case 'insurance':
            priceInCurrency = getItemViewTotalsPrice(view.totals, shouldUseInsuranceMemberPrice)
            break
          case 'cfmr':
            priceInCurrency = getItemViewTotalsPrice(view.totals, shouldUseBookingProtectionMemberPrice)
            break
          default:
            let totals = view.totals
            const appliedLoyaltyUpgrade = getItemViewAppliedLoyaltyUpgrade(view)
            if (appliedLoyaltyUpgrade) {
              totals = appliedLoyaltyUpgrade.targetTotals
            }
            priceInCurrency = getItemViewTotalsPrice(totals, canCheckoutWithMemberPrice)
            break
        }

        priceByItem.set(view.item.itemId, floatify(priceInCurrency))
      }
    })

    if (!priceByItem.size) return EmptyMap

    return priceByItem
  },
)

export default getCheckoutLuxLoyaltyItemsPriceInCurrency
