import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { getItemUniqueKey, getPackageId } from '../accommodation/cart'
import config from 'constants/config'
import { generateCheckoutItemViewOfferCreditKey } from '../businessTraveller/cart'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { dateIsAfter, dateIsBefore, isBookingWithinDateRange } from 'lib/datetime/dateUtils'
import { sum } from 'lib/array/arrayUtils'
import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'

function dummyBedbankItemView(
  item: App.Checkout.BedbankHotelItem,
  kind: 'bedbank',
  offer?: App.BedbankOffer,
): App.Checkout.BedbankAccommodationItemView {
  return {
    kind,
    packageId: getPackageId(item),
    uniqueKey: getItemUniqueKey(item),
    item,
    image: {},
    primaryTitle: '',
    occupancy: { adults: 0, children: 0, infants: 0 },
    removeLabel: 'Remove',
    soldOut: true,
    luxLoyaltyProductType: getLuxLoyaltyProductType(offer),
    totals: {
      price: 0,
      memberPrice: 0,
      value: 0,
      surcharge: 0,
      taxesAndFees: 0,
      memberValue: 0,
      extraGuestSurcharge: 0,
    },
  }
}

export function getBedbankItemView(
  item: App.Checkout.BedbankHotelItem,
  // This is temporary until LPP member pricing is fully released
  luxPlusLPPEnabled: boolean,
  session: App.EnquiryResponse | null,
  offer?: App.BedbankOffer,
  rate?: App.BedbankRate,
  offerCredits?: Record<string, App.BusinessTraveller.StatefulData<App.BusinessTraveller.OfferCredit>>,
  postPurchase?: App.CheckoutCartMode,
  existingOrder?: App.Order,
  ratesFetched?: boolean,
): App.WithDataStatus<App.Checkout.BedbankAccommodationItemView> {
  if (!offer) {
    return {
      hasRequiredData: false,
      data: dummyBedbankItemView(item, 'bedbank', offer),
    }
  }

  // no rate means no availability
  if (!rate) {
    return {
      hasRequiredData: postPurchase === 'change-dates' ? !!ratesFetched : false,
      data: dummyBedbankItemView(item, 'bedbank', offer),
    }
  }

  const pkg = rate.room

  const hasPromo = offer.promotions.length > 0 && item.duration >= offer.promotions[0].los

  const todayWithinOfferPeriod = hasPromo && dateIsAfter(new Date(), new Date(offer.promotions[0].periodSchedule.from)) &&
  dateIsBefore(new Date(), new Date(offer.promotions[0].periodSchedule.to))

  const includePromoInclusions = (offer.type === OFFER_TYPE_BED_BANK) ?
    hasPromo && todayWithinOfferPeriod &&
    isBookingWithinDateRange(item.checkIn, item.checkOut, offer.promotions[0].travelPeriod.from, offer.promotions[0].travelPeriod.to) :
    true

  function getInclusions() {
    if (includePromoInclusions) {
      return offer?.promotions?.find(p => pkg?.promotionId === p.id)?.inclusions
    }

    if (rate?.inclusions) {
      return rate.inclusions.map<App.PackageInclusion>(item => ({
        id: item.id,
        description: item.description,
      }))
    }
  }

  const itemTotals = getBedbankItemViewTotals(rate, item.occupancy, luxPlusLPPEnabled)

  const soldOut = !itemTotals.price

  if (postPurchase == 'change-dates' && 'orderItemId' in item && existingOrder) {
    const orderItem = existingOrder.bedbankItems.find(orderItem => orderItem.id === item.orderItemId)

    if (orderItem) {
      const activeRooms = orderItem.rooms.filter(room => room.status === 'booked')
      const roomsLength = activeRooms.length
      // subtracting old room price from new room, each room is a checkout item so we need to divide sum by room.length
      const orderTotal = Math.round(sum(activeRooms.map(room => room.price)) * 100) / roomsLength

      itemTotals.paidPrice = sum(activeRooms.map(room => room.price))
      itemTotals.newPrice = itemTotals.price

      const priceDiff = Number(((Math.round(itemTotals.price * 100) - orderTotal) / 100).toFixed(2))
      const memberPriceDiff = luxPlusLPPEnabled ? Number(((Math.round(itemTotals.memberPrice * 100) - orderTotal) / 100).toFixed(2)) : 0

      itemTotals.price = priceDiff >= 0 ? priceDiff : 0
      itemTotals.memberPrice = memberPriceDiff >= 0 ? memberPriceDiff : 0

      // clear those prices that we don't want to display
      itemTotals.value = 0
      itemTotals.taxesAndFees = 0
      itemTotals.otherFees!.propertyFees = 0
    }
  }

  if (session?.customerRefundPolicy?.cancellationPolicies) {
    rate.cancellationPolicies = session.customerRefundPolicy.cancellationPolicies
  }

  const data: App.Checkout.BedbankAccommodationItemView = {
    kind: 'bedbank',
    packageId: item.roomId,
    uniqueKey: item.roomId,
    item,
    primaryTitle: pkg?.name ?? '',
    image: pkg?.images[0] ?? offer.image,
    occupancy: item.occupancy,
    rate,
    removeLabel: 'Remove room',
    totals: itemTotals,
    inclusions: getInclusions(),
    soldOut,
    hasMobilePromotion: rate.mobilePromotion,
    luxLoyaltyProductType: getLuxLoyaltyProductType(offer),
    mx: rate.mx,
  }

  let hasRequiredData = true

  if (config.businessTraveller.currentAccountMode === 'business' && offerCredits) {
    const offerCreditKey = generateCheckoutItemViewOfferCreditKey(data)
    const offerCredit = offerCredits[offerCreditKey]
    if (offerCredit?.status === 'success') {
      data.totals.businessTravellerCredits = offerCredit.creditValue
    } else {
      hasRequiredData = false
    }
  }

  if (!session) {
    return {
      hasRequiredData: false,
      data,
    }
  }

  return {
    hasRequiredData,
    data,
  }
}

export function getBedbankItemViewTotals(rate: App.BedbankRate, occupancy: App.Occupants, luxPlusLPPEnabled: boolean) {
  const pricing = rate.occupancyPricing.find(pricing =>
    pricing.occupancy === generateOccupancyStringByRoom(occupancy),
  ) || rate.occupancyPricing[0]

  const memberExclusive = pricing.memberExclusive ?? 0

  // The rounding and the `toFixed` call are to correct for floating point errors
  const price = Number(((Math.round(pricing.exclusive * 100) + Math.round(pricing.taxesAndFees * 100)) / 100).toFixed(2))
  const memberPrice = memberExclusive > 0 ? Number(((Math.round(memberExclusive * 100) + Math.round(pricing.taxesAndFees * 100)) / 100).toFixed(2)) : 0

  const itemTotals: App.Checkout.ItemViewTotals = {
    price,
    memberPrice: luxPlusLPPEnabled ? memberPrice : 0,
    value: pricing.inclusive,
    surcharge: 0,
    taxesAndFees: pricing.taxesAndFees ?? 0,
    otherFees: {
      propertyFees: pricing.propertyFees ?? 0,
    },
    paidPrice: 0,
    newPrice: 0,
    newSurcharge: 0,
    newExtraGuestSurcharge: 0,
    memberValue: 0,
    extraGuestSurcharge: 0,
  }

  return itemTotals
}
