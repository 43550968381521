import 'isomorphic-fetch'
import qs from 'qs'
import request from 'api/requestUtils'
import config from 'constants/config'
import { getWhiteLabelAppConfig } from 'lib/whitelabels/whitelabels'

interface CheckAppVersionResult {
  isMajorOrMinor?: boolean;
  isPatch?: boolean;
  storeVersion: string;
  storeLink: string
}

async function checkAppVersion(): Promise<CheckAppVersionResult | undefined> {
  const iOSAppId = config.wlMobileApp?.ios?.appId
  const androidPackageName = config.wlMobileApp?.android?.packageName

  const whiteLabelAppConfig = getWhiteLabelAppConfig()

  const platform = config.MOBILE_APP_PLATFORM || whiteLabelAppConfig.platform
  const clientVersion = config.MOBILE_APP_VERSION || whiteLabelAppConfig.clientVersion
  const parentBrand = whiteLabelAppConfig?.appName || config.brandConfig.subscriptionParentBrand

  const url = '/api/mobileconfig/white-label-app-version'

  const query = {
    plataform: platform,
    clientVersion,
    parentBrand,
    appId: '',
  }

  if (platform === 'ios' && iOSAppId) {
    query.appId = iOSAppId

    /* This is a workaround to handle a wrong build for the scoopon and cudo ios apps
      where the build 9.13.0 is pointing to the deals website in the webview
      so we need to force the query params to query the right app version
      then force the update to the last version where is already fixed
      we can remove this workaround when has no users with this wrong version
      by checking the app metrics in App Store Connect.
    */
    if (whiteLabelAppConfig?.appName === 'cudo' && clientVersion === '9.13.0') {
      query.appId = '977027217' // cudo ios app id
      query.parentBrand = 'cudo'
    }

    if (whiteLabelAppConfig?.appName === 'scoopon' && clientVersion === '9.13.0') {
      query.appId = '390856019' // scoopon ios app id
      query.parentBrand = 'scoopon'
    }

    const response = await request.get<App.ApiResponse<CheckAppVersionResult>>(`${url}?${qs.stringify(query)}`)
    return response.result
  }

  if (platform === 'android' && androidPackageName) {
    query.appId = androidPackageName
    const response = await request.get<App.ApiResponse<CheckAppVersionResult>>(`${url}?${qs.stringify(query)}`)
    return response.result
  }
}

export default checkAppVersion
