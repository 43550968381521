import { createSelector } from 'reselect'
import getCheckoutPointsCalculationRequests from 'luxLoyalty/selectors/checkout/pointsCalculation/getCheckoutPointsCalculationRequests'
import buildPointsKey from 'luxLoyalty/lib/pointsCalculation/buildPointsKey'
import { EmptyMap } from 'lib/array/arrayUtils'
import getCheckoutLuxLoyaltyItemsPriceInCurrency from 'checkout/selectors/luxLoyalty/getCheckoutLuxLoyaltyItemsPriceInCurrency'

const getCheckoutLuxLoyaltyItemsPointsEarnRatios = createSelector(
  (state: App.State) => state.luxLoyalty.pointsEarnCalculations,
  (state: App.State) => state.auth.account.memberId,
  (state: App.State) => getCheckoutPointsCalculationRequests(state),
  (state: App.State) => getCheckoutLuxLoyaltyItemsPriceInCurrency(state),
  (pointsEarnCalculations, memberId, calculationEarnRequests, itemsPriceInCurrency): Map<string, number> => {
    const pointsEarnRatios = new Map<string, number>()

    calculationEarnRequests.forEach(calculationEarnRequest => {
      const priceInCurrency = itemsPriceInCurrency.get(calculationEarnRequest.itemId ?? '')
      const pointsEarnCalculation = pointsEarnCalculations[buildPointsKey(calculationEarnRequest, memberId ?? '')]
      const itemId = calculationEarnRequest.itemId ?? ''

      if (!pointsEarnCalculation?.data || !priceInCurrency || !itemId) return

      const pointsEarn = pointsEarnCalculation.data.points

      pointsEarnRatios.set(itemId, pointsEarn / priceInCurrency)
    })

    if (!pointsEarnRatios.size) return EmptyMap

    return pointsEarnRatios
  },
)

export default getCheckoutLuxLoyaltyItemsPointsEarnRatios
